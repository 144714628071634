<template>
	<div class="news">
		<span class="overlay" :class="{active: isMenuOpen || isAccessModalOpen}"></span>
		<div class="hero-container">
			<Nav back />
			<div class="header header-standard">
				<HeaderNavBar back />
			</div><!-- header -->
			<div class="header-image about-header-image huge">
                <img class="bg" alt="The Mission to Seafarers" src="../assets/tmts-about.jpeg" />
            </div><!-- header image -->
			<div class="news-hero-content">
				<div class="info">
					<h1 class="news-title">About the app</h1>
					<span class="eyebrow">Happy at Sea</span>
				</div><!-- info -->
			</div>
		</div>
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<div class="news-content">
				<p>Happy at Sea is the world’s first digital seafarers' centre launched by The Mission to Seafarers. Here you will find how to access each of our seafarers' centres and chaplains around the world, make requests for things like a ship visit or shopping and download our wellbeing and mental health material.</p>
				<p>This app has been created for use by the world’s seafarers who visit our seamen’s clubs around the world. By using it, you can connect with your nearest centre and:</p>
				<ul>
					<li>Check in to record your visit and build up a log of your activity</li>
					<li>Access our global port database of 200 ports and 50 countries</li>
					<li>Download the latest news from The Mission to Seafarers</li>
					<li>Access our wellbeing resources</li>
					<li>Take part in the Seafarers Happiness Index.</li>
					<li>The app can used without an internet connection, but for the latest content, you’ll need to connect to the internet when you arrive in port.</li>
				</ul>
				<p>We are incredibly grateful to our supporters for helping us to create this app. In particular, we want to thank DNV, Cargill and the Seafarers Charity.</p>
				<p>Got a question or comment? email: <a href="mailto:crewhelp@mtsmail.org">crewhelp@mtsmail.org</a></p>
			</div><!-- news content -->
			<div class="logo-section">
				<div class="wrapper">
					<span class="logo-title">Supported by</span>
					<div class="logo-wrap">
						<div class="logo-block">
							<img src="../assets/cargill.png" alt="The Mission to Seafarers">
						</div>
						<div class="logo-block">
							<img src="../assets/dnv-logo.png" alt="The Mission to Seafarers">
						</div>
						<div class="logo-block">
							<img src="../assets/seafarers-charity.png"  alt="The Mission to Seafarers">
						</div>
					</div>
				</div><!-- wrapper -->
			</div><!-- logo section -->
		</div>

        <div ref="modalAccess" class="phone-modal with-nav" style="bottom: -600px;" :class="{active: isAccessModalOpen}">
            <span class="modal-line"></span>
            <div class="modal-title-bar">
                <div class="icon-wrap">
                    <RegisterIcon style="width: 27px; height: 27px;" class="icon register-icon" />
                    <span>Create your profile</span>
                </div><!-- icon wrap -->
                <GreyCloseIcon @click="closeAccessModal" style="width: 31px; height: 31px;" class="icon close" />
            </div><!-- modal title bar -->
            <div class="modal-message-block wide">
                <ProfileIcon class="profile-icon" style="width: 50px; height: 50px;" />
                <h3>Please create a profile to access this area.</h3>
                <p>This app will always be free, but creating a profile will give you greater access to the app.</p>
            </div>
            <Button routeName="/create-profile" icon="RegisterIcon" class="btn btn-green btn-icon btn-icon-white btn-icon-small btn-icon-right" text="Okay, I'll create a profile"></Button>
            <Button @click.native="openLoginScreen" icon="LoginIcon" class="btn btn-slate btn-icon btn-icon-white btn-icon-small btn-icon-right" text="I already have a profile"></Button>
            <a @click="closeAccessModal()" class="text-link text-link-grey">I'll create a profile another time &raquo;</a>
        </div><!-- modal -->

		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
    import RegisterIcon from '@/assets/register.svg';
    import ProfileIcon from '@/assets/profile.svg';
    import GreyCloseIcon from '@/assets/close-grey.svg';
    import Button from '@/components/Button.vue';
	export default {
		name: 'About',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
            RegisterIcon,
            ProfileIcon,
            GreyCloseIcon,
            Button
		},
		data () {
	        return {
	        };
	    },
        watch: {
            newsId () {
                this.getArticle();
            }
        },
	    methods: {
            openLoginScreen () {
                this.$router.push({ name: 'Home', params: { showLoginModal: true } });
            },
            closeAccessModal () {
                this.$store.state.isAccessModalOpen = false;
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
            isAccessModalOpen () {
                return this.$store.state.isAccessModalOpen;
            }
		},
		mounted () {
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.about-header-image img.bg {
		opacity: 0.15;
	}
	.logo-section {
		position: relative;
		text-align: center;
		margin-bottom: 50px;
		margin-top: 70px;
		.logo-title {
			text-align: center;
			font-size: 16px;
			line-height: 26px;
			color: #6D7A8B;
			margin-bottom: 20px;
			display: inline-block;
		}
		.logo-wrap {
			display: flex;
			justify-content: center;
			background: #EAF5FA;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 0 10px;
			.logo-block {
				width: 30%;
				height: 110px;
				margin-left: 0;
				margin-right: 0;
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: auto;
				    height: auto;
				    max-width: 70%;
				    max-height: 65%;
				}
			}
		}
	}
	.section-title-wrap-big {
		margin-bottom: 20px;
	}
	.news-hero-content {
		position: relative;
		text-align: left;
		display: flex;
	    justify-content: space-between;
	    margin: 0 28px;
	    align-items: flex-end;
	    min-height: 175px;
    	padding: 0 0 22px;
	    .news-title {
    		font-size: 26px;
    		line-height: 31px;
    		color: $white;
    		display: block;
    		margin-bottom: 12px;
    	}
    	.eyebrow {
    		color: $white;
    	}
	}
	.news-content ::v-deep {
		text-align: left;
		margin-bottom: 50px;
		iframe {
			width: 100%;
			margin: 10px 0 30px;
		}
		h2, h3, h4, h5 {
			color: $dark;
			font-size: 17px;
			letter-spacing: -0.5px;
			font-weight: $weight_600;
			line-height: 26px;
			margin-bottom: 30px;
		}
		h6 {
			color: $orange_base;
			font-size: 17px;
			letter-spacing: -0.5px;
			font-weight: $weight_600;
			line-height: 26px;
			margin-bottom: 22px;
			margin-bottom: 30px;
		}
		p {
			margin-bottom: 22px;
			a {
				color: $blue_base;
				text-decoration: underline;
				font-weight: $weight_500;
			}
		}
		ul {
			list-style: disc;
		    padding-left: 15px;
		    margin-bottom: 25px;
			li {
				font-family: "Inter", sans-serif;
			    font-weight: 400;
			    letter-spacing: -0.5px;
			    font-size: 17px;
			    line-height: 1.6em;
			    color: #6D7A8B;
			    margin-bottom: 10px;
			}
		}
	}
</style>
